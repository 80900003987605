<template>
  <div>
    <el-form ref="ruleFormRef" style="width: 100%" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon>
      <el-form-item label="" prop="ca">
        <el-input v-model="ruleForm.ca" size="default" placeholder="请输入CA" :prefix-icon="Setting" style="width: 300px;" />
      </el-form-item>
      <el-form-item label="" prop="">
        <div style="width: 100%;display: flex;justify-content: space-between;align-items: center">
          <div style="font-size: 14px;color:rgb(96,98,102);display:flex;align-items:center;">
            <div>没有账户？立即</div>
            <div style="color: rgba(255, 141, 26, 1);margin-left:2px;cursor: pointer;" @click="enroll">注册</div>
          </div>
          <div style="font-size: 14px;color:rgb(96,98,102);display:flex;align-items:center;" @click="forget">
            <div>忘记密码</div>
            <div style="width: 16px;height:16px;border-radius:50%;display:flex;justify-content: center;align-items: center;margin-left:5px;cursor: pointer;border:1px solid rgb(96,98,102);">?</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm(ruleFormRef)" style="width: 100%;height: 55px;font-size:18px;">
          立即登录
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
import { Setting } from '@element-plus/icons-vue'
import { reactive, ref } from 'vue'
import { useRouter } from "vue-router";
let router = useRouter()
let formSize = ref('default')
let ruleFormRef = ref()
let ruleForm = ref({
  ca: '',
})
let rules = reactive({
  ca: [
    { required: true, message: '请输入CA', trigger: 'blur' },
    { min: 11, max: 999, message: '请输入正确CA', trigger: 'blur' }
  ]
})

let submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!')
    } else {
      console.log('error submit!', fields)
    }
  })
}
let enroll = () => {
  router.push({ path: '/businessRegistration' })
}
let forget = () => {
  router.push({ path: '/forgotPassword' })
}
</script>
<style scoped>
::v-deep .el-input__wrapper {
  height: 50px;
  background: rgba(231, 241, 253, 0.4);
  box-shadow: none;
}
::v-deep .el-input__prefix-inner {
  position: relative;
}
::v-deep .el-input__prefix-inner::after {
  content: "";
  width: 1px;
  height: 28px;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  background-color: rgb(4, 19, 74);
  opacity: 0.4;
}
::v-deep .el-input__inner {
  margin-left: 10px;
}
</style>