<template>
  <div>
    <el-form ref="ruleFormRef" style="width: 100%" :model="ruleForm" :rules="rules" label-width="auto" class="demo-ruleForm" :size="formSize" status-icon>
      <el-form-item label="" prop="phone">
        <el-input @keyup.enter="handleWatchEnter" v-model="ruleForm.phone" size="default" placeholder="请输入手机号" :prefix-icon="Iphone" style="width: 300px;" @input="activeyzm(ruleFormRef)" />
      </el-form-item>
      <el-form-item label="" prop="yzm">
        <el-input @keyup.enter="handleWatchEnter" v-model="ruleForm.yzm" size="default" placeholder="请输入验证码" :prefix-icon="CopyDocument" style="width: 150px;" />
        <div style="width: 150px;height:50px;">
          <el-button :disabled='IsDisabled' type="primary" size="default" style="width: 100%;height:100%;" @click="getyzm">{{title}}</el-button>
        </div>
      </el-form-item>
      <el-form-item label="" prop="">
        <div style="width: 100%;display: flex;justify-content: space-between;align-items: center">
          <div style="font-size: 14px;color:rgb(96,98,102);display:flex;align-items:center;">
            <div>没有账户？立即</div>
            <div style="color: rgba(255, 141, 26, 1);margin-left:2px;cursor: pointer;" @click="enroll">注册</div>
          </div>
          <div style="font-size: 14px;color:rgb(96,98,102);display:flex;align-items:center;" @click="forget">
            <div>忘记密码</div>
            <div style="width: 16px;height:16px;border-radius:50%;display:flex;justify-content: center;align-items: center;margin-left:5px;cursor: pointer;border:1px solid rgb(96,98,102);">?</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button v-loading.fullscreen.lock="fullscreenLoading" type="primary" @click="submitForm(ruleFormRef)" style="width: 100%;height: 55px;font-size:18px;margin-top: 50px;">
          立即登录
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script setup>
import { Iphone } from '@element-plus/icons-vue'
import { reactive, ref } from 'vue'
import inspect from "@/utils/validate";
import { useRouter } from "vue-router";
import { Phonetoken, Phonevalidate } from "@/api/index.js"
let router = useRouter()
let formSize = ref('default')
let ruleFormRef = ref()
let title = ref('获取验证码')
let CaptchaKey = ref()
let fullscreenLoading = ref(false)
let ruleForm = ref({
  phone: '',
  yzm: '',
})
let IsDisabled = ref(true)
let validateTelphone = (rule, value, callback) => {
  if (!value) {
    callback(new Error('手机号不能为空！'));
  } else if (!inspect.valphone(value)) {
    callback(new Error('请输入正确的手机号！'));
  } else {
    callback();
  }
};
let rules = reactive({
  phone: [
    { required: true, message: '请输入手机号', trigger: 'blur' },
    { min: 11, max: 11, message: '请输入正确手机号', trigger: 'blur' },
    { required: true, validator: validateTelphone, trigger: 'blur' }
  ],
  yzm: [
    { required: true, message: '请输入验证码', trigger: 'blur' }
  ],
})

let submitForm = async (formEl) => {
  if (!formEl) return
  await formEl.validate((valid, fields) => {
    if (valid) {
      fullscreenLoading.value = true
      Phonetoken(ruleForm.value.yzm, CaptchaKey.value, { phone: ruleForm.value.phone, grant_type: "phone" }).then((res) => {
        if (res.code == 200) {
          localStorage.setItem('tokenuser', res.access_token);
          localStorage.setItem('token', JSON.stringify(res));
          localStorage.setItem('Time', new Date().getTime() + 1000 * 60 * 60 * 24 * 7);
          fullscreenLoading.value = false
          location.reload();
        } else {
          IsDisabled.value = true;
        }
      })
    } else {
      console.log('error submit!', fields)
    }
  })
  fullscreenLoading.value = false
}
let activeyzm = (formName) => {
  formName.validateField('phone', (errorMessage) => {
    if (errorMessage) {
      IsDisabled.value = false;
    } else {
      console.log('Phone validation error:', errorMessage);
    }
  });
}
let getyzm = async () => {
  console.log(ruleForm.value.phone, '手机号');
  await Phonevalidate({ phone: ruleForm.value.phone }).then((res) => {
    console.log(res, 'res');
    CaptchaKey.value = res.data.id
  })
  let count = 60;
  title.value = `${count}秒后重新获取`;
  IsDisabled.value = true;
  let timer = setInterval(() => {
    count--;
    if (count > 0) {
      title.value = `${count}秒后重新获取`;
    } else {
      clearInterval(timer);
      title.value = '获取验证码';
      IsDisabled.value = false;
    }
  }, 1000);
}
let enroll = () => {
  router.push({ path: '/businessRegistration' })
}
let forget = () => {
  router.push({ path: '/forgotPassword' })
}
let handleWatchEnter = (e) => {
  if (e.keyCode === 13) {
    console.log(123);
    submitForm(ruleFormRef.value)
  }
}
</script>
<style scoped>
::v-deep .el-input__wrapper {
  height: 50px;
  background: rgba(231, 241, 253, 0.4);
  box-shadow: none;
}
::v-deep .el-input__prefix-inner {
  position: relative;
}
::v-deep .el-input__prefix-inner::after {
  content: "";
  width: 1px;
  height: 28px;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  background-color: rgb(4, 19, 74);
  opacity: 0.4;
}
::v-deep .el-input__inner {
  margin-left: 10px;
}
</style>