const spuerpwd = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[\W])[\da-zA-Z\W]{10,}$/;
const pwd = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/;
// const allTwoPwd = /(?!^(\d+|[a-zA-Z]+|[`~!@#$%^&*()_+<>\-\=\\?:"{},.\/;'[\]]+)$)^[\w`~!@#$%^&*()_+<>\-\=\\?:"{},.\/;'[\]/]{6,20}$/
const allTwoPwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9~!@&%#_]{8,20}$/;
const phone = /^1[3456789]\d{9}$/;
const china = /^[\u4E00-\u9FA5]+$/;
const Num = /^[\d+]+$/;
const NumLett = /^[A-Z\d]+$/;
const NumAllLett = /^[A-Za-z\d]+$/;
const NumLettString = /^[A-Za-z\d\u4E00-\u9FA5\-_()（）@.]+$/;
const fax = /^0[0-9]{2,3}-[0-9]{7,8}$/;
const mac = /^[A-F0-9]{2}(-[A-F0-9]{2}){5}$|^[A-F0-9]{2}(:[A-F0-9]{2}){5}$/;
const idCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
export default {
  valpwd: function(str) {
    return allTwoPwd.test(str);
  },
  valphone: function(str) {
    return phone.test(str);
  },
  valUpwd: function(str) {
    return /[A-Z]/.test(str);
  },
  valChina: function(str) {
    return china.test(str);
  },
  valYesChina: function(str) {
    return /[\u4E00-\u9FA5]/.test(str);
  },
  valYesNumber: function(str) {
    return /[\d+]/.test(str);
  },
  valNum: function(str) {
    return Num.test(str);
  },
  valNumLett: function(str) {
    return NumLett.test(str);
  },
  valNumAllLett: function(str) {
    return NumAllLett.test(str);
  },
  valNumLettString: function(str) {
    return NumLettString.test(str);
  },
  valAmount: function(value) {
    if (value < 0) return true;
    let floatlength =
      parseInt(value) != value ? value.toString().split(".")[1].length : 0;
    let length =
      parseInt(value) != value
        ? value.toString().split(".")[0].length
        : value.toString().length;
    let bool = false;
    if (floatlength == 0) {
      bool = length > 10 ? true : false;
    } else {
      bool =
        (length > 10 && (floatlength > 2 || floatlength <= 2)) ||
        (length <= 10 && floatlength > 2)
          ? true
          : false;
    }
    return bool;
  },
  valfax: function(str) {
    return fax.test(str);
  },
  valMax: function(str) {
    return mac.test(str);
  },
  valIdCard: function(str) {
    return idCard.test(str);
  },
  /**
   * 树形数组遍历返回FILED数组
   * @author sagittarius_Mhx
   * @since  2018.10
   */
  getFullTreeVal: function(val, filed, name, arr, arrtibutes) {
    let res = [];
    for (let n in arr) {
      let FunVal = arrtibutes ? arr[n].arrtibutes[filed] : arr[n][filed];
      if (FunVal == val) {
        return [arr[n][name]];
      } else if (Array.isArray(arr[n].children)) {
        let resp = arrtibutes
          ? this.getFullTreeVal(val, filed, name, arr[n].children, arrtibutes)
          : this.getFullTreeVal(val, filed, name, arr[n].children);
        if (resp.length > 0) {
          res = [arr[n][name]];
          res = res.concat(resp);
        }
      }
    }
    return res;
  },
  /**树形数组遍历返回value**/
  getFullTreeCode: function(val, child) {
    for (var index in child) {
      if (child[index].value == val) {
        return { success: true, valArray: [child[index].value] };
      } else if (child[index].children != null) {
        var result = this.getFullTreeCode(val, child[index].children);
        if (result.success) {
          var _thisArray = [child[index].value];
          _thisArray = _thisArray.concat(result.valArray);
          return { success: true, valArray: _thisArray };
        }
      }
    }
    return { success: false, valArray: null };
  },
  /**树形数组遍历返回label**/
  getFullTreeName: function(val, child) {
    for (var index in child) {
      if (child[index].value == val) {
        return { success: true, valArray: [child[index].label] };
      } else if (child[index].children != null) {
        var result = this.getFullTreeName(val, child[index].children);
        if (result.success) {
          var _thisArray = [child[index].label];
          _thisArray = _thisArray.concat(result.valArray);
          return { success: true, valArray: _thisArray };
        }
      }
    }
    return { success: false, valArray: null };
  },
  /**根据code树形数组遍历返回value**/
  getFullCodeToValue: function(val, child) {
    for (var index in child) {
      if (child[index].arrtibutes.azCode == val) {
        return { success: true, valArray: [child[index].label] };
      } else if (child[index].children != null) {
        var result = this.getFullCodeToValue(val, child[index].children);
        if (result.success) {
          var _thisArray = [child[index].label];
          _thisArray = _thisArray.concat(result.valArray);
          return { success: true, valArray: _thisArray };
        }
      }
    }
    return { success: false, valArray: null };
  },
  /**根据code树形数组遍历返回value**/
  getFullAicNameToAicId: function(val, child) {
    for (var index in child) {
      if (child[index].arrtibutes.aicName == val) {
        return { success: true, valArray: [child[index].value] };
      } else if (child[index].children != null) {
        var result = this.getFullAicNameToAicId(val, child[index].children);
        if (result.success) {
          var _thisArray = [child[index].value];
          _thisArray = _thisArray.concat(result.valArray);
          return { success: true, valArray: _thisArray };
        }
      }
    }
    return { success: false, valArray: null };
  },
  /**根据code树形数组遍历返回value**/
  getFullCodeToId: function(val, child) {
    for (var index in child) {
      if (child[index].arrtibutes.azCode == val) {
        return { success: true, valArray: [child[index].value] };
      } else if (child[index].children != null) {
        var result = this.getFullCodeToId(val, child[index].children);
        if (result.success) {
          var _thisArray = [child[index].value];
          _thisArray = _thisArray.concat(result.valArray);
          return { success: true, valArray: _thisArray };
        }
      }
    }
    return { success: false, valArray: null };
  },
  /**根据code树形数组遍历返回value**/
  getFullCodeToIdAll: function(val, child) {
    for (var index in child) {
      if (child[index].arrtibutes.fullCode == val) {
        return { success: true, valArray: [child[index].value] };
      } else if (child[index].children != null) {
        var result = this.getFullCodeToIdAll(val, child[index].children);
        if (result.success) {
          var _thisArray = [child[index].value];
          _thisArray = _thisArray.concat(result.valArray);
          return { success: true, valArray: _thisArray };
        }
      }
    }
    return { success: false, valArray: null };
  },
  /**枚举数组遍历**/
  getEnumItems: function(val, child) {
    for (var index in child) {
      if (child[index].key == val) {
        return child[index].items;
      }
    }
  },
  /**
   * 获取枚举ENUMS数据
   * @author sagittarius_Mhx
   * @since 2018.7.12
   * */
  getEnums: function(val, child) {
    for (var index in child) {
      if (child[index].key == val) {
        return child[index].enums;
      }
    }
  },
  /**正则替换**/
  replacestr: function(str, regexp, replacement) {
    return str ? str.replace(regexp, replacement) : "";
  },
  /**
   * 格式化按钮的禁用状态
   *
   * @author sagittarius_Mhx
   * @since 2018.7.13
   */
  formatDisable: function(row, name) {
    let that = this;
    if (name == "edit") {
      return that.editBtn(row);
    }
    if (name == "delete") {
      return that.editBtn(row);
    }
    if (name == "submit") {
      return that.submitBtn(row);
    }
    if (name == "publish") {
      return that.publishBtn(row);
    }
  },
  editBtn: function(row) {
    if (
      (row.state == 0 && row.verifyState == 0) ||
      (row.state == 1 && row.verifyState == -1) ||
      (row.state == 1 && row.verifyState == 0) ||
      (row.state == 0 && row.verifyState == -1)
    ) {
      return true;
    }
  },
  submitBtn: function(row) {
    if (row.verifyState == 0) {
      return true;
    }
  },
  publishBtn: function(row) {
    if (row.verifyState == 2 && row.publishState == 0) return true;
  },
  /**
   * 列表数据转化并一起保存
   *
   * @author sagittarius_Mhx
   * @since  2018.7.16
   */
  listAddObject: function(name, list, data, changefiled, changename) {
    for (let index in list) {
      let listData = list[index];
      for (let param in listData) {
        if (changefiled != undefined && changename != undefined) {
          for (let cf in changefiled) {
            if (param == changefiled[cf]) {
              data[name + "[" + index + "]." + changename[cf]] =
                listData[param];
            } else {
              data[name + "[" + index + "]." + param] = listData[param];
            }
          }
        } else {
          data[name + "[" + index + "]." + param] = listData[param];
        }
      }
    }
  },
  /****重写Listobj的方法 */
  ListAddObj(name, list) {
    let data = {};
    for (let index in list) {
      let listData = list[index];
      for (let param in listData) {
        data[name + "[" + index + "]." + param] = listData[param];
      }
    }
    return data;
  },
  /**options类型字段转换**/
  getEnumNameByValue: function(id, options) {
    let label = "";
    options.forEach(function(value, index, array) {
      if (array[index].value == id) {
        label = array[index].label;
      }
    });
    return label;
  },
  /**根据filed值转化成filedname**/
  getTableFiledsName: function(id, filed, filedname, options) {
    let _this = this;
    for (let n in options) {
      if (options[n][filed] == id) {
        return options[n][filedname];
        break;
      }
    }
  },
  /**标段内容自动换行**/
  getEnterName: function(row) {
    let _this = this;
    let arrRow = row != null ? row.split(",") : [];
    let htmldata = "";
    for (let n in arrRow) {
      if (n > 0) {
        htmldata += "<br>";
      }
      htmldata += arrRow[n];
    }
    return htmldata;
  },
  /**格式Cascader多级菜单显示**/
  getCascaderName: function(val, child) {
    var resp = this.getFullTreeName(val, child);
    if (resp.success) {
      var str = "";
      for (var label in resp.valArray) {
        if (label > 0) {
          str += " -> ";
        }
        str += resp.valArray[label];
      }
      return str;
    }
  },
  /**
   * 格式化显示数字的大小写
   *
   * @author Fancy_Li
   * @since 2018年10月8日15:39:33
   */
  formatNum: function(value) {
    let _this = this;
    let cnNums = new Array(
      "零",
      "壹",
      "贰",
      "叁",
      "肆",
      "伍",
      "陆",
      "柒",
      "捌",
      "玖"
    ); //汉字的数字
    let cnIntRadice = new Array("", "拾", "佰", "仟"); //基本单位
    let cnIntUnits = new Array("", "万", "亿", "万亿"); //对应整数部分扩展单位
    let cnDecUnits = new Array("角", "分", "毫", "厘"); //对应小数部分单位
    let cnIntLast = ""; //整型完以后的单位
    let cnDecimalIntLast = "元"; //小数点部分处理
    let maxNum = 9999999.99; //最大处理的数字
    let IntegerNum; //金额整数部分
    let DecimalNum; //金额小数部分
    let ChineseStr = ""; //输出的中文金额字符串
    let parts; //分离金额后用的数组，预定义
    if (value == "" || value == undefined || isNaN(value)) {
      return "请输入数字";
    }
    let money = parseFloat(value);
    // if (money >= maxNum) { return "请输入数字"; }
    if (money == 0) {
      return cnNums[0];
    }
    money = value.toString(); //转换为字符串
    if (money.indexOf(".") == -1) {
      IntegerNum = money;
      DecimalNum = "";
    } else {
      parts = money.split(".");
      IntegerNum = parts[0];
      DecimalNum = parts[1].substr(0, 4);
    }
    if (parseInt(IntegerNum, 10) > 0) {
      //获取整型部分转换
      let zeroCount = 0;
      let IntLen = IntegerNum.length;
      for (let i = 0; i < IntLen; i++) {
        let n = IntegerNum.substr(i, 1);
        let p = IntLen - i - 1;
        let q = p / 4;
        let m = p % 4;
        if (n == "0") {
          zeroCount++;
        } else {
          if (zeroCount > 0) {
            ChineseStr += cnNums[0];
          }
          zeroCount = 0; //归零
          ChineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
        }
        if (m == 0 && zeroCount < 4) {
          ChineseStr += cnIntUnits[q];
        }
      }
      if (DecimalNum == "") {
        ChineseStr += cnIntLast; //整型部分处理完毕
      } else if (DecimalNum != "") {
        ChineseStr += cnDecimalIntLast;
        //小数部分
        let decLen = DecimalNum.length;
        for (let i = 0; i < decLen; i++) {
          let n = DecimalNum.substr(i, 1);
          if (n != "0") {
            ChineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
    }
    if (ChineseStr == "") {
      ChineseStr += cnNums[0];
    }
    return ChineseStr;
  },
  /**
   * 将Base64编码字符串转换成Ansi编码的字符串 zyq 2015/05/20
   *
   * @param input
   *            Base64编码字符串
   * @returns {String} 字符串明文
   */
  decode64: function(input) {
    var keyStr =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    var output = "";
    var chr1,
      chr2,
      chr3 = "";
    var enc1,
      enc2,
      enc3,
      enc4 = "";
    var i = 0;

    if (input.length % 4 != 0) {
      return "";
    }
    var base64test = /[^A-Za-z0-9\+\/\=]/g;
    if (base64test.exec(input)) {
      return "";
    }
    do {
      enc1 = keyStr.indexOf(input.charAt(i++));
      enc2 = keyStr.indexOf(input.charAt(i++));
      enc3 = keyStr.indexOf(input.charAt(i++));
      enc4 = keyStr.indexOf(input.charAt(i++));
      chr1 = (enc1 << 2) | (enc2 >> 4);
      chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
      chr3 = ((enc3 & 3) << 6) | enc4;

      output = output + String.fromCharCode(chr1);
      if (enc3 != 64) {
        output += String.fromCharCode(chr2);
      }
      if (enc4 != 64) {
        output += String.fromCharCode(chr3);
      }
      chr1 = chr2 = chr3 = "";
      enc1 = enc2 = enc3 = enc4 = "";
    } while (i < input.length);
    return output;
  },
  /**
   * uft-8转为utf-16 zyq 2015/05/20
   *
   * @param str
   *            utf-8编码字符串
   * @returns utf-16编码字符串
   */
  utf8to16: function(str) {
    var out, i, len, c;
    var char2, char3;

    out = "";
    len = str.length;
    i = 0;
    while (i < len) {
      c = str.charCodeAt(i++);
      switch (c >> 4) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          // 0xxxxxxx
          out += str.charAt(i - 1);
          break;
        case 12:
        case 13:
          // 110x xxxx 10xx xxxx
          char2 = str.charCodeAt(i++);
          out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
          break;
        case 14:
          // 1110 xxxx 10xx xxxx 10xx xxxx
          char2 = str.charCodeAt(i++);
          char3 = str.charCodeAt(i++);
          out += String.fromCharCode(
            ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
          );
          break;
      }
    }
    return out;
  },
  /**
   * 判断是否为空
   */
  validatenull: function(val) {
    if (typeof val == "boolean") {
      return false;
    }
    if (typeof val == "number") {
      return false;
    }
    if (val instanceof Array) {
      if (val.length == 0) return true;
    } else if (val instanceof Object) {
      if (JSON.stringify(val) === "{}") return true;
    } else {
      if (
        val == "null" ||
        val == null ||
        val == "undefined" ||
        val == undefined ||
        val == ""
      )
        return true;
      return false;
    }
    return false;
  }
};
